export const GET_TODOS_REQUEST = "GET_TODOS_REQUEST";
export const GET_TODOS_SUCCESS = "GET_TODOS_SUCCESS";
export const GET_TODOS_FAILURE = "GET_TODOS_FAILURE";

export const ADD_TODOS_REQUEST = "ADD_TODOS_REQUEST";
export const ADD_TODOS_SUCCESS = "ADD_TODOS_SUCCESS";
export const ADD_TODOS_FAILURE = "ADD_TODOS_FAILURE";

export const UPDATE_TODOS_REQUEST = "UPDATE_TODOS_REQUEST";
export const UPDATE_TODOS_SUCCESS = "UPDATE_TODOS_SUCCESS";
export const UPDATE_TODOS_FAILURE = "UPDATE_TODOS_FAILURE";

export const DELETE_TODOS_REQUEST = "DELETE_TODOS_REQUEST";
export const DELETE_TODOS_SUCCESS = "DELETE_TODOS_SUCCESS";
export const DELETE_TODOS_FAILURE = "DELETE_TODOS_FAILURE";

export const GET_COMPLETED_STATUS_REQUEST = "GET_COMPLETED_STATUS_REQUEST";
export const GET_COMPLETED_STATUS_SUCCESS = "GET_COMPLETED_STATUS_SUCCESS";
export const GET_COMPLETED_STATUS_FAILURE = "GET_COMPLETED_STATUS_FAILURE";
